import React from 'react';
import { createRoot } from 'react-dom/client';
import MediaQuery from 'react-responsive';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css';
import Sidebar from './components/Sidebar';
import Shapes from './components/Shapes';
import Liners from './components/Liners';
import Filters from './components/Filters';
import Decks from './components/Decks';
import Carousel from './components/Carousel';
import Header from './components/Header';
import ConstrolsBar from './components/ControlsBar';
import ConstrolsBarMobileLandscape from './components/ControlsBarMobileLandscape';
import ConstrolsBarMobilePortrait from './components/ControlsBarMobilePortrait';
import InstructionsModal from './components/InstructionsModal';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { ReactComponent as GearIcon } from './images/ui/gear.svg';
import { ReactComponent as InfoIcon } from './images/ui/info-icon.svg';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-9MCCYSVF0B');
ReactGA.send('pageview');

class Visualizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data_url: 'https://visualizer-data.poolsidebycgt.com',
      site_url: 'https://visualizer.poolsidebycgt.com',
      // data_url: 'https://visualizer-data.updates.ed.web.localhost',
      // site_url: 'https://visualizer.updates.ed.build.localhost',
      shape: null,
      shape_name: null,
      liner: {
        liner_id: null,
        liner_name: null,
        liner_desc: null,
        liner_base: null,
        liner_cat1: null,
        liner_cat2: null,
        liner_ico1: null,
        liner_ico2: null,
        liner_col: null,
      },
      deck: {
        deck_id: null,
        deck_name: null,
      },
      filters: {
        collection_id: 'all',
        category_id: 'all',
        water_id: 'all',
        text: '',
      },
      showInstructions: true,
      showCarousel: false,
      disableClick: false,
      showLiners: true,
      showDecks: true,
      showInfo: false,
      controlsBarHeight: 0,
      dayNight: false,
      offCanvas: false,
    };
  }
  handleReset() {
    this.setState({
      shape: null,
      shape_name: null,
      liner: {
        liner_id: null,
        liner_name: null,
        liner_desc: null,
        liner_base: null,
        liner_cat1: null,
        liner_cat2: null,
        liner_ico1: null,
        liner_ico2: null,
        liner_col: null,
      },
      deck: {
        deck_id: null,
        deck_name: null,
      },
      filters: {
        collection_id: 'all',
        category_id: 'all',
        water_id: 'all',
        text: '',
      },
      showInstructions: true,
      showCarousel: false,
      disableClick: false,
      showLiners: true,
      showDecks: true,
      showInfo: false,
      controlsBarHeight: 0,
      dayNight: false,
      offCanvas: false,
    });
  }
  handleShapeClick(x, y) {
    this.setState({
      shape: x,
      shape_name: y,
      showCarousel: !this.state.liner.liner_id && x === '3' ? false : true,
    });
  }
  handleLinerClick(x) {
    this.setState(prevState => ({
      showCarousel: !prevState.liner.liner_id && x.liner_id === '109' ? false : true,
      disableClick: !prevState.liner.liner_id && x.liner_id === '109' ? false : true,
      liner: {
        ...prevState.liner,
        liner_id: x.liner_id,
        liner_name: x.liner_name,
        liner_desc: x.liner_desc,
        liner_base: x.liner_base,
        liner_cat1: x.liner_cat1,
        liner_cat2: x.liner_cat2,
        liner_ico1: x.liner_ico1,
        liner_ico2: x.liner_ico2,
        liner_col: x.liner_col,
      },
      showLiners: prevState.liner.liner_id ? true : false
    }))
  }
  handleDeckClick(x, y) {
    this.setState(prevState => ({
      deck: {
        ...prevState.deck,
        deck_id: x,
        deck_name: y,
      },
      showCarousel: !prevState.deck.deck_id && x === '5' ? false : true,
      disableClick: !prevState.deck.deck_id && x === '5' ? false : true,
      showDecks: prevState.deck.deck_id ? true : false
    }))
  }
  handleFilterCollection(i) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        collection_id: this.state.filters.collection_id === i ? 'all' : i
      }
    }))
  }
  handleFilterCategory(i) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        category_id: this.state.filters.category_id === i ? 'all' : i
      }
    }))
  }
  handleFilterWater(i) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        water_id: this.state.filters.water_id === i ? 'all' : i
      }
    }))
  }
  handleFilterText(i) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        text: i.toLowerCase()
      }
    }))
  }
  handleCarouselLoad(x) {
    if (x === 4) {
      this.setState({
        showCarousel: false,
        disableClick: false
      });
    }
  }
  handleInstructionsClose() {
    this.setState({
      showInstructions: false
    });
  }
  handleButtonClickLiners() {
    this.setState(prevState => ({
      showLiners: !prevState.showLiners
    }));
  }
  handleButtonClickDecks() {
    this.setState(prevState => ({
      showDecks: !prevState.showDecks
    }));
  }
  handleButtonClickInfo() {
    this.setState(prevState => ({
      showInfo: !prevState.showInfo
    }));
  }
  handleDayNight() {
    this.setState(prevState => ({
      dayNight: !prevState.dayNight,
      showCarousel: true,
    }));
  }
  handleShapeSwitch() {
    this.setState(prevState => ({
      shape: prevState.shape === '3' ? '4' : '3',
      shape_name: prevState.shape_name === 'Rectangle' ? 'Freeform' : 'Rectangle',
      showCarousel: true,
    }));
  }
  handleOffCanvas() {
    this.setState(prevState => ({
      offCanvas: !prevState.offCanvas
    }));
  }

  componentDidMount() {
    const url = this.state.data_url + '/liners/list'

    fetch(url)
      .then((result) => result.json())
      .then((result) => {
        this.setState({ data: result, });
      });
  }

  render() {
    const liner_url = this.getLinerUrl();
    return (
      <div className='container-fluid g-0'>
        <MediaQuery maxDeviceHeight={449.98} minDeviceWidth={600}> {/* Mobile Landscape */}
          <div className='row g-0 mobile-landscape'>
            <div className='col-12'>
              <Header
                classesWrapper='header-mobile-landscape user-select-none p-3 me-lg-3'
                classesInner='d-flex align-items-center'
                classesLogo='logo'
                classesHeading='fs-5 ms-1 mb-0'
                classesLink='text-white text-decoration-none'
                onClick={() => this.handleReset()}
              />
              {(this.state.showInstructions) && (
                <InstructionsModal
                  handleShow={() => this.handleInstructionsClose()}
                  showInstructions={this.state.showInstructions}
                  onClick={() => this.handleReset()}
                />
              )}
              {(!this.state.shape) && (
                <div className='wrapper-shapes position-relative ms-lg-4'>
                  <Shapes
                    onClick={(x, y) => this.handleShapeClick(x, y)}
                    shape={this.state.shape} />
                </div>
              )}
              <Carousel
                url={liner_url}
                dataUrl={this.state.data_url}
                setCarouselLoad={(x) => this.handleCarouselLoad(x)}
                carouselLoad={this.state.showCarousel}
                visibility={this.getCarouselVisibility()}
                dayNight={this.state.dayNight}
                setDayNight={() => this.handleDayNight()} />
              {(this.state.deck.deck_id && this.state.liner.liner_id) && (
                <>
                  <Button variant="primary" onClick={() => this.handleOffCanvas()} className="gear me-2 p-1 border-0">
                    <GearIcon alt='Configuration' />
                  </Button>
                  <Offcanvas show={this.state.offCanvas} placement='end' onHide={() => this.handleOffCanvas()} backdrop={false} className='landscape'>
                    <Offcanvas.Header closeButton className='landscape'>
                      <Offcanvas.Title className='landscape'>
                        <Button variant="light" className='text-center info d-flex justify-content-evenly align-items-center' disabled={!this.state.deck.deck_name} onClick={() => this.handleButtonClickInfo()}>
                          {this.state.deck.deck_name ? <> <img alt={this.state.liner.liner_name} src={`${this.state.data_url}/sites/default/files/liners/${this.state.liner.liner_id}/liner.png`} /> <span className='pool-deck text-nowrap ms-2'>{this.state.liner.liner_name} </span><InfoIcon alt='Info' className='ms-2' /></> : ''}
                        </Button>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='landscape'>
                      <ConstrolsBarMobileLandscape
                        liner={this.state.liner}
                        deckName={this.state.deck.deck_name}
                        shape={this.state.shape}
                        setShowLiners={() => this.handleButtonClickLiners()}
                        setShowDecks={() => this.handleButtonClickDecks()}
                        setShape={() => this.handleShapeSwitch()}
                        setShowInfo={() => this.handleButtonClickInfo()}
                      />
                    </Offcanvas.Body>
                  </Offcanvas>
                </>
              )}
              {(this.state.showInfo && this.state.liner.liner_id) && (
                <Modal
                  show={true}
                  backdrop="static"
                  dialogClassName="row mx-auto info-show justify-content-center m-0"
                  contentClassName="col-auto w-100"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickInfo()}
                  aria-labelledby="info-show-title"
                >
                  <Modal.Header className='position-absolute border-0' closeVariant='white' closeButton={this.state.showInfo}>
                  </Modal.Header>
                  <Modal.Body className='p-0'>
                    <Sidebar
                      showLiners={this.state.showLiners}
                      showDecks={this.state.showDecks}
                      shape={this.state.shape}
                      liner={this.state.liner}
                      deck={this.state.deck}
                      dataUrl={this.state.data_url}
                      siteUrl={this.state.site_url}
                      collection={this.state.liner.liner_col}
                      onClick={() => this.handleReset()}
                    />
                  </Modal.Body>
                </Modal>
              )}
              {(this.state.showDecks && this.state.liner.liner_id) && (
                <Modal
                  show={true}
                  backdrop={this.state.deck.deck_id ? true : 'static'}
                  dialogClassName="row mt-0 me-0 decks-selection justify-content-center"
                  contentClassName="col-auto w-auto"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickDecks()}
                  aria-labelledby="decks-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="decks-selection-title">
                      {!this.state.deck.deck_id ? 'Select' : 'Selected (' + this.state.deck.deck_name + ')'} Deck
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Decks
                      onClick={(x, y) => this.handleDeckClick(x, y)}
                      deck={this.state.deck.deck_id}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
              {(this.state.showLiners && this.state.shape) && (
                <Modal
                  show={true}
                  backdrop={this.state.liner.liner_id ? true : 'static'}
                  dialogClassName="row mt-0 me-0 liners-selection justify-content-center"
                  contentClassName="col-auto w-auto"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickLiners()}
                  aria-labelledby="liners-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="liners-selection-title">
                      {!this.state.liner.liner_id ? 'Select' : 'Selected (' + this.state.liner.liner_name + ')'} Liner
                      <Filters
                        url={this.getFiltersUrl()}
                        setFilterCollection={(i) => this.handleFilterCollection(i)}
                        setFilterCategory={(i) => this.handleFilterCategory(i)}
                        setFilterWater={(i) => this.handleFilterWater(i)}
                        setFilterText={(i) => this.handleFilterText(i)}
                        filters={this.state.filters} />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Liners
                      linersData={this.state.data}
                      onClick={(x) => this.handleLinerClick(x)}
                      dataUrl={this.state.data_url}
                      liner={this.state.liner}
                      shape={this.state.shape}
                      filters={this.state.filters}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div >
        </MediaQuery> {/* End Mobile Landscape */}
        <MediaQuery maxDeviceHeight={449.98} maxDeviceWidth={599.98}> {/* Mini Mobile Portrait */}
          <div className='row g-0 mobile-portrait mini-mobile'>
            <div className='col-12'>
              <Header
                classesWrapper='gradient1 user-select-none p-4 me-lg-3'
                classesInner='d-flex align-items-center'
                classesLogo='logo'
                classesHeading='fs-4 ms-3 mb-0'
                classesLink='text-white text-decoration-none'
                onClick={() => this.handleReset()}
              />
              {(!this.state.shape) && (
                <>
                  <InstructionsModal
                    handleShow={() => this.handleInstructionsClose()}
                    showInstructions={this.state.showInstructions}
                    onClick={() => this.handleReset()}
                  />
                  <div className='wrapper-shapes position-relative ms-lg-4'>
                    <Shapes
                      onClick={(x, y) => this.handleShapeClick(x, y)}
                      shape={this.state.shape} />
                  </div>
                </>
              )}
              <Carousel
                url={liner_url}
                dataUrl={this.state.data_url}
                setCarouselLoad={(x) => this.handleCarouselLoad(x)}
                carouselLoad={this.state.showCarousel}
                visibility={this.getCarouselVisibility()}
                dayNight={this.state.dayNight}
                setDayNight={() => this.handleDayNight()} />
              {(this.state.showDecks && this.state.liner.liner_id) && (
                <Modal
                  show={true}
                  backdrop={this.state.deck.deck_id ? true : 'static'}
                  dialogClassName="row mt-0 mx-auto me-sm-0 ms-sm-auto decks-selection justify-content-center"
                  contentClassName="col-auto w-auto"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickDecks()}
                  aria-labelledby="decks-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="decks-selection-title">
                      {!this.state.deck.deck_id ? 'Select' : 'Selected (' + this.state.deck.deck_name + ')'} Deck
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Decks
                      onClick={(x, y) => this.handleDeckClick(x, y)}
                      deck={this.state.deck.deck_id}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
              {(this.state.showLiners && this.state.shape) && (
                <Modal
                  show={true}
                  backdrop={this.state.liner.liner_id ? true : 'static'}
                  dialogClassName="row mt-0 mx-auto me-sm-0 ms-sm-auto liners-selection justify-content-center"
                  contentClassName="col-auto w-auto"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickLiners()}
                  aria-labelledby="liners-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="liners-selection-title">
                      {!this.state.liner.liner_id ? 'Select' : 'Selected (' + this.state.liner.liner_name + ')'} Liner
                      <Filters
                        url={this.getFiltersUrl()}
                        setFilterCollection={(i) => this.handleFilterCollection(i)}
                        setFilterCategory={(i) => this.handleFilterCategory(i)}
                        setFilterWater={(i) => this.handleFilterWater(i)}
                        setFilterText={(i) => this.handleFilterText(i)}
                        filters={this.state.filters} />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Liners
                      linersData={this.state.data}
                      onClick={(x) => this.handleLinerClick(x)}
                      dataUrl={this.state.data_url}
                      liner={this.state.liner}
                      shape={this.state.shape}
                      filters={this.state.filters}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
            </div>
            {(this.state.deck.deck_id && this.state.liner.liner_id) && (
              <div className='info-controls p-3'>
                <ConstrolsBarMobilePortrait
                  liner={this.state.liner}
                  deckName={this.state.deck.deck_name}
                  shape={this.state.shape}
                  setShowLiners={() => this.handleButtonClickLiners()}
                  setShowDecks={() => this.handleButtonClickDecks()}
                  setShape={() => this.handleShapeSwitch()}
                  setShowInfo={() => this.handleButtonClickInfo()} />
              </div>
            )}
            {(this.state.showInfo && this.state.liner.liner_id) && (
              <Modal
                show={true}
                backdrop="static"
                dialogClassName="row mx-auto info-show justify-content-center m-0"
                contentClassName="col-auto w-100"
                backdropClassName="modal-transparent"
                onHide={() => this.handleButtonClickInfo()}
                aria-labelledby="info-show-title"
              >
                <Modal.Header className='position-absolute border-0' closeVariant='white' closeButton={this.state.showInfo}>
                </Modal.Header>
                <Modal.Body className='p-0'>
                  <Sidebar
                    showLiners={this.state.showLiners}
                    showDecks={this.state.showDecks}
                    shape={this.state.shape}
                    liner={this.state.liner}
                    deck={this.state.deck}
                    dataUrl={this.state.data_url}
                    siteUrl={this.state.site_url}
                    collection={this.state.liner.liner_col}
                    onClick={() => this.handleReset()}
                  />
                </Modal.Body>
              </Modal>
            )}
          </div>
        </MediaQuery> {/* End Mini Mobile Portrait */}
        <MediaQuery minDeviceHeight={450} maxDeviceWidth={991.98}> {/* Mobile Portrait */}
          <div className='row g-0 mobile-portrait'>
            <div className='col-12'>
              <Header
                classesWrapper='gradient1 user-select-none p-4 me-lg-3'
                classesInner='d-flex align-items-center'
                classesLogo='logo'
                classesHeading='fs-4 ms-3 mb-0'
                classesLink='text-white text-decoration-none'
                onClick={() => this.handleReset()}
              />
              {(!this.state.shape) && (
                <>
                  <InstructionsModal
                    handleShow={() => this.handleInstructionsClose()}
                    showInstructions={this.state.showInstructions}
                    onClick={() => this.handleReset()}
                  />
                  <div className='wrapper-shapes position-relative ms-lg-4'>
                    <Shapes
                      onClick={(x, y) => this.handleShapeClick(x, y)}
                      shape={this.state.shape} />
                  </div>
                </>
              )}
              <Carousel
                url={liner_url}
                dataUrl={this.state.data_url}
                setCarouselLoad={(x) => this.handleCarouselLoad(x)}
                carouselLoad={this.state.showCarousel}
                visibility={this.getCarouselVisibility()}
                dayNight={this.state.dayNight}
                setDayNight={() => this.handleDayNight()} />
              {(this.state.showDecks && this.state.liner.liner_id) && (
                <Modal
                  show={true}
                  backdrop={this.state.deck.deck_id ? true : 'static'}
                  dialogClassName="row mt-0 mx-auto me-sm-0 ms-sm-auto decks-selection justify-content-center"
                  contentClassName="col-auto w-auto"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickDecks()}
                  aria-labelledby="decks-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="decks-selection-title">
                      {!this.state.deck.deck_id ? 'Select' : 'Selected (' + this.state.deck.deck_name + ')'} Deck
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Decks
                      onClick={(x, y) => this.handleDeckClick(x, y)}
                      deck={this.state.deck.deck_id}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
              {(this.state.showLiners && this.state.shape) && (
                <Modal
                  show={true}
                  backdrop={this.state.liner.liner_id ? true : 'static'}
                  dialogClassName="row mt-0 mx-auto me-sm-0 ms-sm-auto liners-selection justify-content-center"
                  contentClassName="col-auto w-auto"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickLiners()}
                  aria-labelledby="liners-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="liners-selection-title">
                      {!this.state.liner.liner_id ? 'Select' : 'Selected (' + this.state.liner.liner_name + ')'} Liner
                      <Filters
                        url={this.getFiltersUrl()}
                        setFilterCollection={(i) => this.handleFilterCollection(i)}
                        setFilterCategory={(i) => this.handleFilterCategory(i)}
                        setFilterWater={(i) => this.handleFilterWater(i)}
                        setFilterText={(i) => this.handleFilterText(i)}
                        filters={this.state.filters} />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Liners
                      linersData={this.state.data}
                      onClick={(x) => this.handleLinerClick(x)}
                      dataUrl={this.state.data_url}
                      liner={this.state.liner}
                      shape={this.state.shape}
                      filters={this.state.filters}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
            </div>
            {(this.state.deck.deck_id && this.state.liner.liner_id) && (
              <div className='info-controls p-3'>
                <ConstrolsBarMobilePortrait
                  liner={this.state.liner}
                  deckName={this.state.deck.deck_name}
                  shape={this.state.shape}
                  setShowLiners={() => this.handleButtonClickLiners()}
                  setShowDecks={() => this.handleButtonClickDecks()}
                  setShape={() => this.handleShapeSwitch()}
                  setShowInfo={() => this.handleButtonClickInfo()} />
              </div>
            )}
            {(this.state.showInfo && this.state.liner.liner_id) && (
              <Modal
                show={true}
                backdrop="static"
                dialogClassName="row mx-auto info-show justify-content-center m-0"
                contentClassName="col-auto w-100"
                backdropClassName="modal-transparent"
                onHide={() => this.handleButtonClickInfo()}
                aria-labelledby="info-show-title"
              >
                <Modal.Header className='position-absolute border-0' closeVariant='white' closeButton={this.state.showInfo}>
                </Modal.Header>
                <Modal.Body className='p-0'>
                  <Sidebar
                    showLiners={this.state.showLiners}
                    showDecks={this.state.showDecks}
                    shape={this.state.shape}
                    liner={this.state.liner}
                    deck={this.state.deck}
                    dataUrl={this.state.data_url}
                    siteUrl={this.state.site_url}
                    collection={this.state.liner.liner_col}
                    onClick={() => this.handleReset()}
                  />
                </Modal.Body>
              </Modal>
            )}
          </div>
        </MediaQuery> {/* End Mobile Portrait */}
        <MediaQuery minDeviceHeight={450} minDeviceWidth={992}> {/* Desktop */}
          <div className='row g-0 desktop'>
            <div className='col-12 col-lg-9'>
              <ConstrolsBar
                linerName={this.state.liner.liner_name}
                deckName={this.state.deck.deck_name}
                shape={this.state.shape}
                setShowLiners={() => this.handleButtonClickLiners()}
                setShowDecks={() => this.handleButtonClickDecks()}
                setShape={() => this.handleShapeSwitch()}
              />
              {(!this.state.shape) && (
                <div className='wrapper-shapes position-relative ms-lg-4'>
                  <Shapes
                    onClick={(x, y) => this.handleShapeClick(x, y)}
                    shape={this.state.shape} />
                </div>
              )}
              <Carousel
                url={liner_url}
                dataUrl={this.state.data_url}
                setCarouselLoad={(x) => this.handleCarouselLoad(x)}
                carouselLoad={this.state.showCarousel}
                visibility={this.getCarouselVisibility()}
                dayNight={this.state.dayNight}
                setDayNight={() => this.handleDayNight()} />
              {(this.state.showDecks && this.state.liner.liner_id) && (
                <Modal
                  show={true}
                  backdrop={this.state.deck.deck_id ? true : 'static'}
                  dialogClassName="decks-selection mt-0 me-0"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickDecks()}
                  aria-labelledby="decks-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="decks-selection-title">
                      {!this.state.deck.deck_id ? 'Select' : 'Selected (' + this.state.deck.deck_name + ')'} Deck
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Decks
                      onClick={(x, y) => this.handleDeckClick(x, y)}
                      deck={this.state.deck.deck_id}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
              {(this.state.showLiners && this.state.shape) && (
                <Modal
                  show={true}
                  backdrop={this.state.liner.liner_id ? true : 'static'}
                  dialogClassName="liners-selection mt-0 me-0"
                  backdropClassName="modal-transparent"
                  onHide={() => this.handleButtonClickLiners()}
                  aria-labelledby="liners-selection-title"
                >
                  <Modal.Header closeButton={this.state.deck.deck_id}>
                    <Modal.Title id="liners-selection-title">
                      {!this.state.liner.liner_id ? 'Select' : 'Selected (' + this.state.liner.liner_name + ')'} Liner
                      <Filters
                        url={this.getFiltersUrl()}
                        setFilterCollection={(i) => this.handleFilterCollection(i)}
                        setFilterCategory={(i) => this.handleFilterCategory(i)}
                        setFilterWater={(i) => this.handleFilterWater(i)}
                        setFilterText={(i) => this.handleFilterText(i)}
                        filters={this.state.filters} />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Liners
                      linersData={this.state.data}
                      onClick={(x) => this.handleLinerClick(x)}
                      dataUrl={this.state.data_url}
                      liner={this.state.liner}
                      shape={this.state.shape}
                      filters={this.state.filters}
                      disabled={this.state.disableClick} />
                  </Modal.Body>
                </Modal>
              )}
            </div>
            <div className='col-12 col-lg-3'>
              <Sidebar
                showLiners={this.state.showLiners}
                showDecks={this.state.showDecks}
                shape={this.state.shape}
                liner={this.state.liner}
                deck={this.state.deck}
                dataUrl={this.state.data_url}
                siteUrl={this.state.site_url}
                collection={this.state.liner.liner_col}
                onClick={() => this.handleReset()}
              />
            </div>
          </div >
        </MediaQuery> {/* End Desktop */}
      </div >
    );
  }

  getLinerUrl() {
    return (!this.state.shape && !this.state.liner.liner_id && !this.state.deck.deck_id) ? this.state.data_url + '/liner/' + (this.state.dayNight ? 'night' : 'day') + '/3/109/5'
      : (this.state.shape && !this.state.liner.liner_id && !this.state.deck.deck_id) ? this.state.data_url + '/liner/' + (this.state.dayNight ? 'night' : 'day') + '/' + this.state.shape + '/109/5'
        : (this.state.shape && this.state.liner.liner_id && !this.state.deck.deck_id) ? this.state.data_url + '/liner/' + (this.state.dayNight ? 'night' : 'day') + '/' + this.state.shape + '/' + this.state.liner.liner_id + '/5'
          : this.state.data_url + '/liner/' + (this.state.dayNight ? 'night' : 'day') + '/' + this.state.shape + '/' + this.state.liner.liner_id + '/' + this.state.deck.deck_id;
  }
  getFiltersUrl() {
    return this.state.data_url + '/liners/filters/' + this.state.filters.collection_id + '/' + this.state.filters.category_id + '/' + this.state.filters.water_id;
  }
  getCarouselVisibility() {
    return this.state.shape ? 'visible' : 'hidden';
  }
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Visualizer />);